import style from './Maintenance.module.css'

import Image from 'next/image'
import classNames from 'classnames/bind'
import useTranslation from 'next-translate/useTranslation'

import Logo from './Logo'

export default function Maintenance () {
  const c = classNames.bind(style)
  const { t } = useTranslation('main')
  return (
    <section className={c('maintenance')}>
      <div className={c('logo')}>
        <Logo width={52} />
        <h1>{t('sghome-starsaver')}<span>{t('sghome-starsaver2')}</span></h1>
      </div>
      <div className={c('message')}>
        <h3>System under maintenance,<br />we will be back shortly</h3>
      </div>
      <div className={c('partnerlogos')}>
        <span>{t('sghome-backedby')}</span>
        <a href='https://www.aspial.com' target='_blank' rel='noopener noreferrer'>
          <Image src='/images/aspial-logo.png' width={90} height={41} alt='Aspial' />
        </a>
        <a href='https://silverbullion.com.sg' target='_blank' rel='noopener noreferrer'>
          <Image src='/images/silver-bullion-logo.svg' width={130} height={42} alt='Silver Bullion' />
        </a>
      </div>
    </section>
  )
}
